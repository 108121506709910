import "./index.scss";
import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-container">
      <div className="contact-high">
        <a
          href="mailto:MAIL@MARCWALDOW.COM"
          target="_blank"
          rel="noopener noreferrer"
        >
          mail@marcwaldow.com
        </a>
      </div>
      <br></br>
      <div className="contact-low">
        <a href="Tel:+49 163 3925063">+49 163 3925063</a>
      </div>
      <br></br>
      <div className="contact-low">
        <a
          className="instagram-icon"
          href="https://www.instagram.com/marc_waldow/?hl=en"
        >
          @marc_waldow
        </a>
      </div>
    </div>
  );
};

export default Contact;
