import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";
import commissionsImage from "../../assets/images/commissions-order/10.jpg";
import lifeStyleImage from "../../assets/images/lifestyle-order/64.jpg";
import loadingIcon from "../../assets/animations/spinner.gif";

const Home = () => {
  const [lifestyleImageLoaded, setLifestyleImageLoaded] = useState(false);
  const [commissionsImageLoaded, setCommissionsImageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    handleLifestyleImageLoad();
    handleCommissionsImageLoad();
  }, []);

  const handleLifestyleImageLoad = () => {
    setLifestyleImageLoaded(true);
  };

  const handleCommissionsImageLoad = () => {
    setCommissionsImageLoaded(true);
  };

  const handleImageLoad = (event) => {
    event.target.classList.add("loaded"); // Add the 'loaded' class to trigger the fade-in effect
  };

  return (
    <>
      <div className="home-image-container">
        <div className="lifestyle-container">
          <NavLink to="/work/lifestyle">
            {lifestyleImageLoaded ? (
              <img
                className="lifestyle"
                src={lifeStyleImage}
                alt="eunice"
                onLoad={handleImageLoad}
                loading="eager"
              />
            ) : (
              <div className="loading-container">
                <img
                  className="loading-icon"
                  src={loadingIcon}
                  alt="Loading..."
                  loading="eager"
                  onLoad={handleImageLoad}
                />
              </div>
            )}
            <div className="work-title">lifestyle</div>
          </NavLink>
        </div>
        <div className="clients-container">
          <NavLink to="/work/commissions">
            {commissionsImageLoaded ? (
              <img
                className="clients"
                src={commissionsImage}
                alt="furniture"
                onLoad={handleImageLoad}
                loading="eager"
              />
            ) : (
              <div className="loading-container">
                <img
                  className="loading-icon"
                  src={loadingIcon}
                  alt="Loading..."
                />
              </div>
            )}
            <div className="work-title">commissions</div>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Home;
