import "./index.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import marc1 from "../../../../assets/images/commissions-order/1.jpg";
import marc2 from "../../../../assets/images/commissions-order/2.jpg";
import marc3 from "../../../../assets/images/commissions-order/3.jpg";
import marc4 from "../../../../assets/images/commissions-order/4.jpg";
import marc5 from "../../../../assets/images/commissions-order/5.jpg";
import marc6 from "../../../../assets/images/commissions-order/6.jpg";
import marc7 from "../../../../assets/images/commissions-order/7.jpg";
import marc8 from "../../../../assets/images/commissions-order/8.jpg";
import marc9 from "../../../../assets/images/commissions-order/9.jpg";
import marc10 from "../../../../assets/images/commissions-order/10.jpg";
import marc11 from "../../../../assets/images/commissions-order/11.jpg";
import marc12 from "../../../../assets/images/commissions-order/12.jpg";
import marc13 from "../../../../assets/images/commissions-order/13.jpg";
import marc14 from "../../../../assets/images/commissions-order/14.jpg";
import marc15 from "../../../../assets/images/commissions-order/15.jpg";
import marc16 from "../../../../assets/images/commissions-order/16.jpg";

const Commissions = () => {
  const navigate = useNavigate();
  const [imagesReady, setImagesReady] = useState(false);

  const clickHandler = () => {
    navigate("/work", { replace: true });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Set a timeout to mark images as ready after the delay
    const timeoutId = setTimeout(() => {
      setImagesReady(true);
    }, []);

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageLoad = (event) => {
    event.target.classList.add("loaded"); // Add the 'loaded' class to trigger the fade-in effect
  };

  return (
    <>
      <div className={`clients-container${imagesReady ? " fade-in" : ""}`}>
        <div className="clients-image-container">
          <img
            className="portrait-start-2"
            src={marc1}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="landscape-2"
            src={marc2}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-sixth"
            src={marc3}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-eighth"
            src={marc4}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-fourth"
            src={marc5}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-sixth"
            src={marc6}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-fifth"
            src={marc7}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-eleven"
            src={marc8}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-12"
            src={marc9}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-eighth"
            src={marc10}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="clients-fourth"
            src={marc11}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait"
            src={marc12}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="clients-12"
            src={marc13}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="clients-eleven"
            src={marc14}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="clients-ninth"
            src={marc15}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-end"
            src={marc16}
            alt="marc"
            onLoad={handleImageLoad}
          />
          {/* <img className="portrait" src={marc17} alt="marc" onLoad={handleImageLoad} />
          <img className="portrait" src={marc21} alt="marc" onLoad={handleImageLoad} />
          <img className="landscape-2" src={marc23} alt="marc" onLoad={handleImageLoad} />
          <img className="landscape-end" src={marc26} alt="marc" onLoad={handleImageLoad} />
          <img className="portrait-start-2" src={marc27} alt="marc" onLoad={handleImageLoad} /> */}
        </div>
        <div className="button-bottom">
          <button onClick={clickHandler}>{"<"}</button>
        </div>
      </div>
    </>
  );
};

export default Commissions;
