import "./index.scss";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { useState } from "react";

const Sidenav = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const closeMenu = () => {
    setNavbarOpen(false);
  };

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <div className="site-container">
      <div className="sidenav">
        <div className="side-menu-top">
          <NavLink
            className={({ isActive }) =>
              ["nav-link3", isActive ? "active" : null]
                .filter(Boolean)
                .join(" ")
            }
            to="/"
          >
            marc waldow
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              ["nav-link", isActive ? "active" : null].filter(Boolean).join(" ")
            }
            to="/about"
          >
            about
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              ["nav-link", isActive ? "active" : null].filter(Boolean).join(" ")
            }
            to="/work"
          >
            work
          </NavLink>
        </div>
      </div>
      <div className="bottomnav">
        <div className="sidenav-bottom">
          <div className="contact-box">
            <NavLink
              className={({ isActive }) =>
                ["nav-link3", isActive ? "active" : null]
                  .filter(Boolean)
                  .join(" ")
              }
              to="/contact"
            >
              contact
            </NavLink>
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <div className="top-center">
          <NavLink
            className={({ isActive }) =>
              ["nav-link3", isActive ? "active" : null]
                .filter(Boolean)
                .join(" ")
            }
            onClick={() => closeMenu()}
            to="/"
          >
            MARC WALDOW
          </NavLink>
        </div>
        <button className="hamburger-menu" onClick={handleToggle}>
          {navbarOpen ? (
            <>
              <MdClose
                className={({ isActive }) =>
                  ["nav-link", isActive ? "active" : null]
                    .filter(Boolean)
                    .join(" ")
                }
                style={{
                  color: "black",
                  width: "30px",
                  height: "30px",
                  background: "white",
                  marginTop: "2px",
                }}
              />
            </>
          ) : (
            <FiMenu
              style={{
                color: "black",
                width: "30px",
                height: "30px",
                background: "white",
                hover: "black",
                marginTop: "2px",
              }}
            />
          )}
        </button>
        <div className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
          <div className="mobile-dropdown">
            <NavLink
              className={({ isActive }) =>
                ["nav-link", isActive ? "active" : null]
                  .filter(Boolean)
                  .join(" ")
              }
              onClick={() => closeMenu()}
              to="about"
            >
              about
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                ["nav-link", isActive ? "active" : null]
                  .filter(Boolean)
                  .join(" ")
              }
              onClick={() => closeMenu()}
              to="work"
            >
              work
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                ["nav-link", isActive ? "active" : null]
                  .filter(Boolean)
                  .join(" ")
              }
              onClick={() => closeMenu()}
              to="contact"
            >
              contact
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;
