import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Work from "./pages/Work";
import Sidenav from "./components/Sidenav";
import Lifestyle from "./pages/Work/WorkList/Lifestyle";
import Commissions from "./pages/Work/WorkList/Commissions";

function App() {
  return (
    <div className="App">
      <Sidenav />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<Work />} />
          <Route path="work/lifestyle" element={<Lifestyle />} />
          <Route path="work/commissions" element={<Commissions />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
