import "./index.scss";
import { NavLink } from "react-router-dom";

const Work = () => {
  return (
    <>
      <div className="work-container">
        <NavLink
          className={({ isActive }) =>
            ["nav-link-projects", isActive ? "active" : null]
              .filter(Boolean)
              .join(" ")
          }
          to="/work/lifestyle"
        >
          lifestyle
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            ["nav-link-projects", isActive ? "active" : null]
              .filter(Boolean)
              .join(" ")
          }
          to="/work/commissions"
        >
          commissions
        </NavLink>
      </div>
    </>
  );
};

export default Work;
