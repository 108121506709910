import "./index.scss";
import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="about-container">
        <div className="about-paragraph">
          I'm a Berlin-based photographer working with a diverse set of clients
          including BMW, Kneipp, IKEA and Volkswagen. I like to work with both
          established brands and growth-orientated organizations that recognize
          the value of telling their story visually. Building long term working
          relationships with my clients is central to my practice, whether it's
          conceptualizing campaigns or providing them with strong and compelling
          images.
        </div>
      </div>
    </>
  );
};

export default About;
